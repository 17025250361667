import blankPerson from "../blank-person.png";
import Vector from "../Vector.svg";
import React,{ useEffect, useState } from "react";
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
export default function Settings(){
  const [businessName,setbusinessName] = useState('');
  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');
  const [passwordConfirm,setPasswordConfirm] = useState('');
  useEffect(()=>{
    axios.get(process.env.REACT_APP_BACKEND_URL+'/api/app/publisher/business-settings',{withCredentials:true}).then(
      (res)=>{
        if(password == passwordConfirm && res.status==200)
        {
          setbusinessName(res.data.businessName);
          setEmail(res.data.email);
        }
      }
    ).catch(function(error){});
  });
  function updateUser(e){
    e.preventDefault();
    var formData = {
      email: email,
      businessName:businessName,
      password: password
    };
  }
return (
    <div className="container-fluid">
        <div>
        <div className="Settings-box">
            <div class="Inner-Settings">
                <img src={blankPerson} id="person-image" width="200px"/>
                <h3>My Account</h3>
                <form class="settings-form">
  <div class="form-group ">
    <div class="basic-info">
    <div>
    <label for="exampleInputEmail1">Email address</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" value={email} onChange={(e)=>{setEmail(e.target.value)}} required></input>
    <small id="emailHelp" class="form-text text-muted">Corporate Email Recommended.</small>
    </div>
    <div>
    <label for="exampleInputEmail1">business name</label>
    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="abc Ltd" value={businessName} onChange={(e)=>{setbusinessName(e.target.value)}} required></input>
    <small id="emailHelp" class="form-text text-muted">Displayed publicaly for job seekers.</small>
   
    </div>
    </div>
  </div>
  <div class="form-group">
    <form onSubmit={updateUser}>
    <label for="exampleInputPassword1">Password</label>
    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" onChange={(e)=>{setPassword(e.target.value)}}></input>
    <small id="emailHelp" class="form-text text-muted">Leave empty if you don't want to change.</small>
    <label for="exampleInputPassword1">Confirm Password</label>
    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" onChange={(e)=>{setPasswordConfirm(e.target.value)}}></input>
    </form>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1"></input>
    <label class="form-check-label" for="exampleCheck1">Check me out</label>
  </div>
  <button type="submit" onSubmit={updateUser} class="btn btn-primary">Update</button>
</form>
            </div>
        </div>
        </div>
    </div>
);
}