import { useState } from "react";
import FirstTab from "./FirstTab";
import axios from 'axios';
export default function Modal({jobid,setisFinished,isFinished}) {
  const [file, setFile] = useState({
    selectedFile: null
  });
  const [ApplicantName, setApplicantName] = useState("");
  const [showloader,setshowloader] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    let formData = new FormData();
    formData.append("file", file.selectedFile);
    setshowloader(true);
    axios.post(process.env.REACT_APP_BACKEND_URL+`/api/app/job/${(new URLSearchParams(window.location.search).get('PositionId')==null)?jobid:new URLSearchParams(window.location.search).get('PositionId')}/applicant?ApplicantName=${ApplicantName}`,formData
    ).then((res)=>{
      if(res.status==204){
        setisFinished(true);
        setshowloader(false);

      }
    });
  };
  return (
      <form onSubmit={handleSubmit}>
        <div className="modalBox">
          {showloader && <div className="internal-loading">
            <div className="loader-parent">
              <div className="loader"></div>
              </div>
          </div>}
          <h3 className="headding">How to Apply?</h3>
          <p className="instruction"> Write Your Name ,Upload Your CV and Click apply</p>
          {!isFinished?(
          <div className="Tabs">
            
            <div className="pName">
              <input
                type="text"
                placeholder="Your Name"
                onChange={(event) => setApplicantName(event.target.value)}
              ></input>
            </div>
            <div className="outlet">
            <FirstTab file={file} isFinished={isFinished} setFile={setFile} />
            </div>
            <button type="submit" className="submitBtn">
              Apply
            </button>
          </div>):<div className="border border-1 border-danger">Thank you,you have applied sucessfully for this job</div>}
        </div>
      </form>
  );
}
