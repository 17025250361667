import Navbar from 'react-bootstrap/Navbar';
import MainPageChild from './MainPageChild';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import Profile from './Profile';
import JobSearch from './JobSearch';
import Modal from './ModalGrocery/Modal';
import JobCategories from './JobCategories';
import {useEffect, useState} from 'react';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import image1 from '../images/image1.png';
import image2 from '../images/image2.png';
import image3 from '../images/image3.png';

function MainPage() {
  const [title,setTitle]=useState(null);
  const [location,setLocation]=useState(null);
  useEffect(()=>{
    function convertToPascal(s){
      return s.replace(/(\w)(\w*)/g,
      function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase();});
    }
    $("#search-title").autocomplete({
      source: function( request, response ) {
        $.getJSON( process.env.REACT_APP_BACKEND_URL+"/api/app/job/getautocomplete", request, function( data, status, xhr ) {
          response(data.map(function(item) {
            return {
              label: convertToPascal(item.title),
              value: convertToPascal(item.title)
            };
          }));
      })
    },
    select: function(event, ui) {

      var TABKEY = 9;
     this.value = ui.item.value;

     if (event.keyCode == TABKEY) { 
         event.preventDefault();
         this.value = this.value + " ";
         $('#search-title').focus();
     }
    
  }
}
    ); 
    $("#search-location").autocomplete({
      source: function( request, response ) {
        $.getJSON( process.env.REACT_APP_BACKEND_URL+"/api/app/city/getautocomplete", request, function( data, status, xhr ) {
          response(data.map(function(item) {
            return {
              label: item.name+" ("+item.state_code+") ,"+item.country_code,
              value: item.name+" ("+item.state_code+") ,"+item.country_code
            };
          }));
      }); 
      }
    });
  },[]);

  function search(e){
    e.preventDefault();
   navigate(`/SearchForJob/?title=${title}&location=${$('#search-location').val()}`);

  }
  const navigate = useNavigate()
  return (
   <>
   {/* <Header/> f*/}
   <header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4">
      <a href="/" class="d-flex align-items-center col-md-3 mb-2 mb-md-0 px-5  ml-1 text-dark text-decoration-none">
        <img src="../images/mainLogo.png" title="logo" height="38px"/>
      </a>
      <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
        <li><span class="nav-link px-3 link-secondary navListText " onClick={() => navigate("/")}  >Home</span></li>
        <li><span class="nav-link px-3 link-dark navListText" onClick={() => navigate("job_Categories")} >Job Categories</span></li>
        {/* <li><span  class="nav-link px-3 link-dark navListText" onClick={() => navigate("/company")}  >Company</span></li>*/}
        <li><span  class="nav-link px-3 link-dark navListText"  onClick={() => navigate("aboutus")} >About Us</span></li>
      </ul>

      <div class="d-flex gap-2 flex-wrap text-end px-5 ">
        <button type="button" class=" color-Yellow me-2 px-4"  onClick={()=>{navigate('login')}}>Login</button>
        <button type="button" class="color-btn px-4" onClick={()=>{navigate('register')}}>Sign-up</button>
      </div>
    </header>
   <div class="fluid-container"> 
    <section className='sectionFix' > 
      <div className='position-absolute looking-to-hire'>
        <div className='mb-2 looking-to-hire-div pt-5'>Looking to hire someone?
Reach Candidates from places around the world through our portal </div>
        <button className=' post-button-jobs mx-auto p-2' onClick={()=>{navigate('register')}}>Post jobs for free</button>
      </div>

      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between pt-3 col-md-12" > 
        <div className=''>
          <p className='Certified'>100% Certified PlatForm</p>
          <p className='skilled'>  Find the right job<br/> for your career</p>
          <p className='Creative'>Search in any category <br/> from development to a marketing</p>
          <div className='flex-wrap align-items-center'>
          <Navbar expand="lg" >
        <div className='' >
          <div>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse className='collapse-Nav' id="navbarScroll" >
            <Form className="d-flex flex-wrap" /*action="/SearchForJob*/ onSubmit={search} method="get">
            <input placeholder="Marketing Manager" aria-label="Search" name="title" id="search-title" type="search" class="me-2 form-control" 
            style={{height: "60px", width: "250px", marginLeft: "0px", margintop: "0px", background: "rgb(249, 250, 251)", marginbottom: "12px"}}
            onChange={e=>setTitle(e.target.value)}
            ></input>
            <Form.Control
              type="search"
              placeholder="New York City (NY)"
              className="me-2 formOneControl"
              aria-label="Search"
              id="search-location"
              name="location"
              onChange={e=>setLocation(e.target.value)}
              
            />
            <Button
            id="search-button"
              style={{
                marginLeft:'20px',
                height:'60px',
                width: '150px',
                backgroundColor: '#2e1500',
                color: '#fff',
                outlineColor: '#e96320',
                borderColor: '#fff',
                marginBottom:"12px",
              }}
              type="submit"
            >
              Search Now
            </Button>
          </Form>
          </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
            <div className='Popular' >
            <h5>
              Popular Categories :
            </h5>
            </div>
            <div className='UxDeSign d-flex flex-wrap align-items-center ' >
              <p  >Sales</p>
              <p>Engineers</p>
              <p> Developers</p>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-around person-photo'>
        <img src="../images/slider.png" title="logo" class="faceImg" />
        </div>
        </div>
    </section>
   {/* <section className='images-section'>
      <div className='intro-images-cards'>
    <swiper-container slides-per-view="1" centeredSlides="true" spaceBetween="0" effect="cards" loop="true" className='images2-slides'>
  <swiper-slide><img src={image1} /></swiper-slide>
  <swiper-slide><img src={image2} /></swiper-slide>
  <swiper-slide><img src={image3} /></swiper-slide>
  ...
</swiper-container>
</div>
            </section>*/}
    <section className='d-flex flex-row justify-content-evenly align-items-center flex-wrap images-section'>
    <img src={image1} />
    <img src={image2} />
    <img src={image3} />
    </section>
    <section className='align-items-center fixTrMargin '  >
      <div className='trusted  flex-wrap align-items-center text-center'>
          {/*<h4>trusted parterners</h4>
          
          <div className='d-flex flex-wrap align-items-center social  justify-content-md-center Social ' >
          <p>facebook</p>
          <p><span className='micro' ><BsSlack /></span>slack</p>
          <p>Google</p>
          <p><span className='micro'><BsMicrosoft /></span>Microsoft</p>  
          </div>
            */}
      </div>
    </section>
    <section>
    <p  className='demand'>Most Demanded Job Categories</p>
       <JobCategories />
    </section>
    <section>
      <MainPageChild />
    </section>
    <section>
      {/* <CartSlider /> */}
      <Modal />
    </section>
    <section>
      <div className='create-account'>
        <div className='create-account-header'>Create an Account to enjoy the full benefits</div>
        <button className='button-signup' onClick={()=>{navigate('/register')}}>Sign Up</button>
      </div>
    </section>
    <section className='section-spacing' >
       <Profile />
    </section>
    <section>
      <JobSearch />
    </section>
  </div>
   </>
  );
}

export default MainPage;