import { Button, Form, Navbar } from "react-bootstrap"
import Logo from '../images/mainLogo.png';

export default function SearchNavBar(){
    return(
        <Navbar expand="lg">
        <div className='container-fluid Navbr-Lets'>
          <Navbar.Brand >
           <a href="/"><img src={Logo} id="logo" class="logo"  style={{  height:"38px" }} /></a>
          </Navbar.Brand>
          <div style={{
          backgroundColor: '#FF5400',
          marginBottom: '10px',
          paddingBottom: '30px',
          width:'100%',
          
        }}
        id="upperContainer"
        >
          <Navbar.Toggle aria-controls="navbarScroll" />
          <div>
          <Navbar.Collapse className='collapse-Nav' id="navbarScroll">
            <form className="element"  action="/SearchForJob" method="get" onSubmit={()=>{window.open('/SearchForJob?q='+document.getElementById('SearchBox').value ,"_self")}}>
              <div class="d-flex" id="searchBar" style={{marginLeft:'39px'}} >
                
            <Form.Control
              type="search"
              placeholder="Marketing Manager"
              className="me-2 Occupation"
              aria-label="Search"
              style={{
                height:'60px',
              }}
              id="SearchBox"
              name="q"
            />

           {/*<Form.Control
              type="search"
              placeholder="Location"
              className="me-2 Occupation secondary"
              aria-label="Search"
              style={{
                height:'60px',
              }}
              
            />*/} 

            <Button
                style={{
                  height:'60px',
                  width: '184px',
                  backgroundColor: '#e96320',
                  color: '#fff',
                  outlineColor: '#e96320',
                  borderColor: '#fff',
                }}
                onClick={() =>window.open('/SearchForJob?q='+document.getElementById('SearchBox').value ,"_self")}
                id="SearchButton"
                className="secondary"

            >
              Search Now
            </Button>
            
            </div>
          </form>
            {/*<img src={navicon} alt="" title='' style={{}}/*/}
          </Navbar.Collapse>
          </div>
        </div>
        </div>
      </Navbar>
    )
}