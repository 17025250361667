import { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';
export default function Applicants(){
    const [applicants ,setapplicants]= useState(null);
    const { id } = useParams();
    const navigate = useNavigate();
    useEffect(()=>{
         axios.get(process.env.REACT_APP_BACKEND_URL+'/api/app/job/applicants/?jobid='+id,{withCredentials: true}).then(
            (res)=>{
                if(res.status==200){
                    setapplicants(res.data);
                }
            }
        );
    },[]); 
    if(applicants=== null){
        return <div className='loading-result-text'>...Loading</div>
    }
     return (
        <div className="Applicants-Container bg-white">
          <div className="margin-left back-button">
            <button className="btn btn-primary cursor-pointer" onClick={()=>{navigate(`/dashboard/myjobs/`)}}>
            <IonIcon icon={arrowBackOutline} className="scale-200"></IonIcon>
            </button>
          </div>
          <br></br>
          {applicants.length === 0 ? (
            <div className='loading-result-text'>No applicants found</div>
          ) : (
            applicants.map((applicant) => (
              <div className="JobListItem" key={applicant.id}>
                <div className="JobListItemContent">
                  <div className="d-flex flex-column justify-content-around h-100">
                    <div>Name: {applicant.applicantName}</div>
                    <div>
                      <button className="btn btn-secondary" onClick={() => {navigate(`applicant/${applicant.id}`)}}>
                        View CV
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-around h-100">
                    <div>Date: {applicant.creationTime.slice(0,10)}</div>
                    <div>Location: {applicant.location}</div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      );
    }