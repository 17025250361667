
import Sidebar from "./Sidebar.js";
import Settings from "./Settings.js";
import MyJobs from "./MyJobs.js";
import React, { useState } from "react";
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  navigate,
  BrowserRouter,
  Outlet,
  MemoryRouter,
  
} from "react-router-dom";
import Applicants from "./Applicants";
import ApplicantView from "./ApplicantView";
import { IonIcon } from '@ionic/react';
import {menuSharp } from 'ionicons/icons';
export default function Content(){
    function togglesidebar(){
        //move sidebar to the left
        document.getElementsByClassName('main-sidebar')[0].classList.toggle('sidebarHidden');
        //toggle main view margin
        document.getElementById('mainView').classList.toggle('mainViewMarginLeft');
        document.getElementById('overlay').classList.toggle('hidden');

      }
return(
    <div  id="mainView">
        <div className="Content">
        <div className="topheader">
        <IonIcon icon={menuSharp} id="menu_toggle" onClick={togglesidebar}></IonIcon>
        </div>
        <Outlet/>
        <Routes>
        <Route path="/myjobs" element={<MyJobs/>} />
         <Route path="/settings" element={<Settings/>} /> 
         <Route path="/myjobs/job/:id" element={<Applicants/>}/>
         <Route path="/myjobs/job/:id/applicant/:applicantid" element={<ApplicantView/>} />
         </Routes>
         </div>
    </div>
);
}