import MobileLogin from '../mobile_login.svg';
import {useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';
export default function ResetPassword({toggleIsActive}){
    const [showSpinner, setShowSpinner] = useState(false);
    const [showBackground, setShowBackground] = useState(false);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const handleClose = () => setOpen(true);
    const handleOpen = () => setOpen(true);
    const navigate = useNavigate();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    
    const formData = {
        Email: email,
        appName: 'string',
        returnUrl: 'string',
        returnUrlHash: "string",
      };
    const handleResetButtonClick = (e) =>{
        e.preventDefault();
    /*setShowSpinner(prevShowSpinner => !prevShowSpinner);
    setShowBackground(prevShowSpinner => !prevShowSpinner);*/
    toggleIsActive();
    axios.post(backendUrl+'/api/app/users/send-password-reset-code', formData)
    .then((resp) => {
      toggleIsActive();
        if (resp.status==204) {
            // The login was successful, redirect the user to the home page
            console.log(resp)
          handleOpen();
        } else {
            // The login failed, show an error message
            alert('error');
        }
    })
    .catch(error => {
        alert('error catched');

        console.log(error);
    });

    }
    const style = {
        position:  'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '0px solid #000',
        boxShadow: 24,
        p: 4,
      };
     
return (
    <div class="top-container">
        <div class="img-container">
        <img src={MobileLogin} class="img-fluid"/>
        </div>
        <div>
            Password Reset Sent!
            
        </div>
    <div id="forget-password-container">
        <form onSubmit={handleResetButtonClick}>
    <div className={`${showSpinner ? 'spinner-local' : ''}`}></div>
      <div className={`${showBackground ? 'background-grey' : ''}`}></div>
  
    <div class="d-flex flex-column align-items-center">
        <h1>
    Enter your email to reset
    </h1>
    {/*<label for="exampleInputEmail1">Email:</label>*/}
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" onChange={(e) => setEmail(e.target.value)}/>
    <input type="submit" class="form-control btn btn-primary col-3" id="ForgetPasswordButton"  aria-describedby="emailHelp" placeholder="Email"/>
            </div>
            </form>
            </div>
            <Modal
        open={open}
        onClose={handleClose}
      >

        <Box sx={style}>
          <h1>Password Reset Sent! </h1>
            <h2>Please check the link in your email.</h2>
        </Box>
      </Modal>
    </div>
);
}