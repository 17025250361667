import ImageLaptop from '../laptop.png';
import {useEffect, useState} from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import $ from 'jquery';
export default function Login({toggleIsActive}){
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  useEffect(()=>{
     axios.post(backendUrl+"/api/app/users/is-user-logged-in",{}, {withCredentials: true}).then(
      (resp) => {
        if(resp.data.loggedin)
        navigate(`/dashboard/myjobs/`);
      }
    ); 
  },[]);
  const onSubmit = async(e) => {
    e.preventDefault();

    var formData = {
      userNameOrEmailAddress: username,
      password: password,
      rememberMe: true
    };
    function modifyKeysToParenthesis(formData) {
      const modifiedObj = {};
    
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          const modifiedKey = `"${key}"`;
          modifiedObj[modifiedKey] = formData[key];
        }
      }
    
      return modifiedObj;
    }
    //formData = modifyKeysToParenthesis(formData);
    // Submit the form data to the server
    toggleIsActive();
    await axios.post(backendUrl+"/api/account/login", formData, {withCredentials: true,headers:{credentials:'include'}})
        .then((resp) => {
            if (resp.data.description=='Success') {
              toggleIsActive();
              navigate('/dashboard/myjobs');
            } else {
              if(resp.data.description=='InvalidUserNameOrPassword'){
                $('.invalidUserNameOrPassword').addClass('active');
              toggleIsActive();
              }
            }
        })
        .catch(error => {
          alert("data.error catched");
        }
        
        );
        await axios.get(process.env.REACT_APP_BACKEND_URL+'/abp/Swashbuckle/SetCsrfCookie',{withCredentials:true});
};

    return (
        <div id="view-container">
          <header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4">
      <a href="/" class="d-flex align-items-center col-md-3 mb-2 mb-md-0 px-5  ml-1 text-dark text-decoration-none">
        <img src="../images/mainLogo.png" title="logo"  height="38px"/>
      </a>
      <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
        <li><span class="nav-link px-3 link-secondary navListText " onClick={() => navigate("/")}  >Home</span></li>
        <li><span class="nav-link px-3 link-dark navListText" onClick={() => navigate("/job_Categories")} >Job Categories</span></li>
        {/* <li><span  class="nav-link px-3 link-dark navListText" onClick={() => navigate("/company")}  >Company</span></li>*/}
        <li><span  class="nav-link px-3 link-dark navListText"  onClick={() => navigate("/aboutus")} >About Us</span></li>
      </ul>
      <div class="d-flex gap-2 flex-wrap text-end px-5">
        <button type="button" class=" color-Yellow me-2 px-4" onClick={() => navigate("/login")}>Login</button>
        <button type="button" class="color-btn px-4" onClick={() => navigate("/register")}>Sign-up</button>
      </div>
    </header>
    <div id="login-container">
    <img src={ImageLaptop} id="table"/>
    <div class="form login-form">
      <form onSubmit={async(e)=>{await onSubmit(e)}}>
        <h1 class="login-heading">Login</h1>
        <p class="invalidUserNameOrPassword">Invalid username or password!</p>
        <div class="form-group">
    <label for="exampleInputEmail1">Email address</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" value={username} onChange={(e) => setUsername(e.target.value)} />
  </div>
  <div class="form-group">
    <label for="exampleInputPassword1">Password</label>
    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
    <div class="d-flex flex-row justify-content-between links">
    <label class="form-check-label" for="exampleCheck1">Remember Me</label>
    <a href="">Forgot Password</a>
    </div>
  </div>
  <button type="submit" class="btn btn-primary w-100 radius-0">Login</button>
  </form>
    </div>

    </div>
    </div>
        );
}