import ImageLaptop from '../laptop.png';
import axios from 'axios';
import {useState} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
export default function Register({toggleIsActive}){
  const [username, setUsername] = useState('');
  const [email, setEmailaddress] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => navigate('/login');
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

const navigate = useNavigate();
useEffect(()=>{
  axios.post(backendUrl+"/api/app/users/is-user-logged-in",{}, {withCredentials: true}).then(
    (resp) => {
     if(resp.data.loggedin)
     navigate(`/dashboard/myjobs/`);
   }
 ); 
},[]);
  const onSubmit = (e) => {
    e.preventDefault();

    const formData = {
      userName: email,
      emailAddress: email,
      password: password,
      userRole: "Business",
      "businessName": "",
      "appName": "string",
    };
      const style = {
    position:  'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
  };
console.log(backendUrl)
    // Submit the form data to the server
    toggleIsActive();
    axios.post(backendUrl+'/api/app/users/register', formData, {withCredentials: true})
        .then((resp) => {
          toggleIsActive();
            if (resp.status==200) {
                // The login was successful, redirect the user to the home page
              handleOpen();
            } else {
                // The login failed, show an error message
                alert('error');
            }
        })
        .catch(error => {
            console.log(error);
        });
};
const style = {
  position:  'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};
    return (
      
        <div id="view-container">
          <header class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4">
      <a href="/" class="d-flex align-items-center col-md-3 mb-2 mb-md-0 px-5  ml-1 text-dark text-decoration-none">
        <img src="../images/mainLogo.png" title="logo"  height="38px"/>
      </a>
      <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
        <li><span class="nav-link px-3 link-secondary navListText " onClick={() => navigate("/")}  >Home</span></li>
        <li><span class="nav-link px-3 link-dark navListText" onClick={() => navigate("/job_Categories")} >Job Categories</span></li>
        {/* <li><span  class="nav-link px-3 link-dark navListText" onClick={() => navigate("/company")}  >Company</span></li>*/}
        <li><span  class="nav-link px-3 link-dark navListText"  onClick={() => navigate("/aboutus")} >About Us</span></li>
      </ul>
      <div class="d-flex gap-2 flex-wrap text-end px-5">
        <button type="button" class=" color-Yellow me-2 px-4" onClick={() => navigate("/login")}>Login</button>
        <button type="button" class="color-btn px-4" onClick={() => navigate("/register")}>Sign-up</button>
      </div>
    </header>
        <div id="login-container" >
    <div src={ImageLaptop} id="table" />
    <div class="form register-form">
      <form onSubmit={onSubmit}>
        <h1 class="login-heading">Register</h1>
        <div class="d-flx">
        <div class="form-group ">
    <label for="exampleInputEmail1">Email address</label>
    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" value={email} onChange={(e) => {setEmailaddress(e.target.value);}}/>
  </div>
  </div>
  <div class="form-group password-classes"><div>
  <label for="exampleInputPassword1">Password</label>
  
    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
    </div>
    <div>
    <label for="exampleInputPassword1">Confirm Password</label>
    <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Confirm Password"/>
    </div>
  </div>
  <div class="form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
  </div>
  <button type="submit" class="btn btn-primary w-100 radius-0">Register</button>
  </form>
    </div>
    </div>
    <Modal
        open={open}
        onClose={handleClose}
      >

        <Box sx={style}>
          <h1>Registration successful! </h1>
         
            <h2>Please check your email to confirm your account.</h2>
        </Box>
      </Modal>
    </div>
        );
}