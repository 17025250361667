import { useNavigate } from "react-router-dom";
import {useState} from 'react';
export default function JobList({jobs,handleOpen,activateAutoComplete}){
    const navigate = useNavigate();
    /*function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }*/
      function MoveRight(){
        document.getElementById('PdfContainer').classList.toggle('Left');
      }
   
    return(
        <div>
        <div className="margin-left">
            <button className="btn btn-primary my-3" onClick={()=>{handleOpen();activateAutoComplete()}} >Add Job +</button>
        </div>
        {jobs.map((job,index) => (
                <div key={index} className="JobListItem">
                <div className="d-flex flex-column justify-content-around h-100">
                    <span>Name: {job.title}</span>
                    <span><button className="btn btn-orange" onClick={()=>{navigate(`job/${job.id}`)}}>View Job</button></span>
                </div>
                <div className="d-flex flex-column justify-content-around h-100">
                    <span>Date:{job.creationTime.slice(0,10)}</span>
                    <span>location:{job.city.name},{job.city.country_code}</span>
                </div>
            </div>
        ))
        }
        </div>)
    }