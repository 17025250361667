import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import MainPage from './Component/MainPage';
import Landing from './Component/Landing';
import JobPostingPage from './Component/JobPostingPage';
import NewJobSearchPage from './Component/newJobSearchPage';
import JobSearchPage from './Component/JobSearchPage';
import PointForm from './Component/PointForm';
import Fourth from './Component/Fourt';
import FormDetail from './Component/FormDetail';
import './App.css';
import { useState } from 'react';
import Login from './Component/Login';
import Register from './Component/Register';
import EmailConfirmation from './Component/EmailConfirmation';
import ResetPassword from './Component/reset-password';
import ResetPasswordConfirm from './Component/reset-password-confirm';
import Dashboard from './Component/Dashboard';
import './Component/index.css';
import './styles_dashboard.css';
import './styles.css';
import { useEffect } from 'react';
import { register } from 'swiper/element/bundle';
function App() {
  const [isActive, setIsActive] = useState(false);
    function toggleIsActive(){
      setIsActive(prevIsActive => !prevIsActive)
    }
   useEffect(()=>{
    register();
   },[]);
   return (
    <>
   <Router>
   <div id={isActive ? '':'loading-screen'}>
        <div class="spinner"></div>
        <div class="grey-background"></div>
        </div>
     <Routes>
     <Route path="/" element={<MainPage />} />
      <Route path="/SearchForJob" element={<NewJobSearchPage />} />
      <Route path="/Apply" element={<JobPostingPage />} />
      <Route path="job_Categories" element={<Landing /> } />
      <Route path="/aboutUs" element={<Fourth />} />
      <Route path="/SubmitForm" element={<PointForm />} />   
      <Route path="/form" element={<FormDetail />} />
      <Route path='/login' element={<Login toggleIsActive={toggleIsActive}/>} />
      <Route path='/register' element={<Register toggleIsActive={toggleIsActive}/>} />
      <Route path='/email-confirmation' element={<EmailConfirmation toggleIsActive={toggleIsActive}/>} />
      <Route path='/forgot-password' element={<ResetPassword toggleIsActive={toggleIsActive}/>} />
      <Route path='/password-reset-confirm' element={<ResetPasswordConfirm toggleIsActive={toggleIsActive} />} />
      <Route path='/dashboard/*' element={<Dashboard isActive={isActive} setIsActive={setIsActive}/>} />
    </Routes> 
     </Router> 
    </>
  );
}
export default App;
