import logodash from "../logodash.svg";
import SignOut from "../SignOut.svg";
import React, {useEffect,useRef, useState } from 'react';
import {useNavigate,useLocation} from "react-router-dom";
import {sidebarItems} from '../sidebarItems.js';
import { IonIcon } from '@ionic/react';
import { menuSharp,closeOutline } from 'ionicons/icons';
import axios from 'axios';
export default function Sidebar({sendIsActiveToApp}) {
  const location = useLocation();
  const currentRoute = location.pathname;
  const routeParts = currentRoute.split('/');
  const secondRoutePart = '/dashboard/'+routeParts[2];
 const navigate = useNavigate();
 const [isSelected, setIsSelected] = useState(false);
 const [selectedItem, setSelectedItem] = useState(sidebarItems.find(item => item.route === secondRoutePart));
 const [bodyClass, setBodyClass] = useState('');
 const spinnerRef = useRef(null);
 const handleLogoutClick = () => {
  sendIsActiveToApp();
  axios.get(process.env.REACT_APP_BACKEND_URL+'/api/account/logout',{withCredentials: true}).then((res)=>{
     sendIsActiveToApp();
    navigate('/login');
  });
 

 }
function ShrinkSidebar(){
  document.getElementById('mainView').classList.toggle('view-shrinked');
  document.getElementById('mainView').classList.toggle('view-shrinked');
  //for(int i=0;i<)
  document.getElementsByClassName('main-sidebar')[0].classList.toggle('main-sidebar-shrinked');
}
useEffect(() => {
  document.body.className = bodyClass;
}, [bodyClass]);

 const handleClick = async(item) => {
  await setSelectedItem(item)
  navigate(item.route)
  console.log(selectedItem);
};
function togglesidebar(){
  //move sidebar to the left
  document.getElementsByClassName('main-sidebar')[0].classList.toggle('sidebarHidden');
  //toggle main view margin
  document.getElementById('mainView').classList.toggle('mainViewMarginLeft');
  document.getElementById('overlay').classList.toggle('hidden');

}
  return (
      <div className="main-sidebar">
         <IonIcon icon={menuSharp} id="sidebarToggle" onClick={togglesidebar}></IonIcon>
       

         {/*<IonIcon icon={menuSharp} id="menu-toggle" onClick={ShrinkSidebar}></IonIcon>*/}
        
        <img src={logodash} id="logoimage" />
        <div className="seperator"></div>
        <div className="nav d-flex flex-column">
        {sidebarItems.map((item) => (
          <div
            key={item.id}
            className={`${item.id === selectedItem?.id ? 'select' : ''}`}
            onClick={() => handleClick(item)}
          >
          <div><img src={item.icon}/></div>
          <div>{item.name}</div>
        </div>
      ))}
          <div id="logout-container" onClick={handleLogoutClick}>
            <div>
              <img src={SignOut} />
            </div>
            <div >Logout</div>
          </div>
        </div>
      </div>

  );
}
