import Vector from "../Vector.svg";
import React,{ useEffect,useState } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import JobList from './JobList.js';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';
import axios from 'axios';
export default function MyJobs({toggleIsActive}) {
  const [open, setOpen] = React.useState(false);
  const [jobs, setJobs] =useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [jobTitle, setjobTitle] = useState('');
  const [companyName, setcompanyName] =useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [jobLocation, setJobLocation] =useState('');
  const [applicationDeadline, setApplicationDeadline] =  useState('');
  const style = {
    position:  'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
  };
  useEffect(()=>{
axios.get(process.env.REACT_APP_BACKEND_URL+'/api/app/job/publisher-jobs',{withCredentials:true}).then((res)=>{
  setJobs(res.data);

});
  } ,[]);
  async function activateAutoComplete (){
  console.log('3')

    await setTimeout(() => {}, 1);
     $("#jobLocation").autocomplete({
      source: function( request, response ) {
        console.log('secondtest')
        $.getJSON( process.env.REACT_APP_BACKEND_URL+"/api/app/city/getautocomplete", request, function( data, status, xhr ) {
          response(data.map(function(item) {
            return {
              label: item.name+" ("+item.state_code+") ,"+item.country_code,
              value: item.name+" ("+item.state_code+") ,"+item.country_code
            };
          }));
      })
    }}); 
  }
  async function SubmitForm(e){
    e.preventDefault();
    handleClose();
    console.log($("#jobLocation").val());
    setJobLocation($("#jobLocation").val())
    var cityName = $("#jobLocation").val().split("(")[0].trim();
    var country_code = $("#jobLocation").val().split(",")[1];
    var state_code = $("#jobLocation").val().split("(")[1].split(")")[0].trim();
    var data = {
      Title:jobTitle,
      companyName:companyName,
        Description:jobDescription,
        jobLocation:country_code+","+state_code+","+cityName,
        ApplicationDeadLine:applicationDeadline,
      };
      /*await axios.get(process.env.REACT_APP_BACKEND_URL+'/abp/Swashbuckle/SetCsrfCookie',{withCredentials:true});*/
   await axios.post(process.env.REACT_APP_BACKEND_URL+'/api/app/job/job',data, {withCredentials: true}).then((res)=>{
    if(res.status==200){
      window.location.reload();
    }
   });

  }
  if(jobs===null){
    return <div className='loading-result-text'>...Loading</div>
}
  return (
    <div className="container-fluid">  
    {jobs.length===0?(
    <div>
      <div className="addAJobView" onClick={async()=>{handleOpen(); activateAutoComplete()}}>
        <div className="addJob">Add A Job to get started</div>
        <div id="applyIcon">
          <img src={Vector} id="applyIcon"/>
        </div>
        <Button onClick={handleOpen} >Open modal</Button>
      </div>
    </div>
    ):<JobList jobs={jobs} handleOpen={handleOpen} activateAutoComplete={activateAutoComplete} />}
    
    <div>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <div id="form-fill">
          <h1>Fill The Form</h1>
    <form class="modal-form" onSubmit={async(e)=>{await SubmitForm(e)}}>
    <label for="jobTitle fs-5">Job Title:</label>
    <input type="text" id="jobTitle" name="jobTitle"  onChange={(e) => setjobTitle(e.target.value)} required/>
    <label for="companyName">Company Name:</label>
    <input type="text" id="companyName" name="companyName" onChange={(e) => setcompanyName(e.target.value)} required/>

    <label >Job Description:</label>     
    <CKEditor
              editor={ClassicEditor}
              data="<p>Description Goes here</p></br>"
              onChange={ ( event, editor ) => {
                const data = editor.getData();
                setJobDescription(editor.getData())
            } }
            />
    <div className="location">
    <div id="dropdown"></div>

    <label for="jobTitle fs-5">JobLocation:</label>
      <input type="search" id="jobLocation"  autocomplete="off" class="jobLocation w-100 ui-autocomplete-input" onChange={(e) =>{  console.log("Input value changed:", e.target.value); setJobLocation(e.target.value)}}></input>
    </div>
    <label for="applicationDeadline">Application Deadline:</label>
    <input type="date" id="applicationDeadline" name="applicationDeadline" onChange={(e) =>{ setApplicationDeadline(e.target.value)}} required/>
    <button type="submit">Add Job</button>
    </form>
    </div>
   </Box>
      </Modal>
    </div>
    </div>
  );
}
