import React ,{useEffect}from 'react'
import { Col, Navbar, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import $ from 'jquery';
import axios from 'axios';
import { connect } from 'react-redux';
import Logo from '../images/mainLogo.png';

 
class JobSearchPage extends React.Component {
  increment = () => {
    this.props.dispatch({
      type: "INCREMENT"
    });
  };
   html_entity_decode(message) {
    return message.replace(/[<>'"]/g, function(m) {
      return '&' + {
        '\'': 'apos',
        '"': 'quot',
        '&': 'amp',
        '<': 'lt',
        '>': 'gt',
      }[m] + ';';
    });
  }
   async displayMore (){
    //const count = useSelector((state) => state.counter.value)
    //const dispatch = useDispatch()
    console.log('this.props.count ='+this.props.count)
   
    console.log('dispached')
    
    //dispatch(increment())
     
    axios.post('https://api.jobmark.org/JobFind/SearchForJob?term='+new URLSearchParams(window.location.search).get('q')+'&p='+(this.props.count)).then(response=>{
      Object.entries([response.data])?.map((element)=>
      {
        
          Object.entries(element[1]).map((internalElement)=> {//each Job comes here
            
          Object.entries(internalElement[1]).map((key,val)=>{//each attribute (For Job) comes here
            //6 criteria to Consider
            
            if(key[0]=='Title'){
            this.setState((previousState)=> 
            {
              
              return {
                objects:[...previousState?.objects], details:[[...previousState?.details[0]??[],key[1]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
              }
            }
            
            );
            
            }
            if(key[0]=='Date'){
            this?.setState((previousState)=> 
            {
              return {
                objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[],key[1]],[...previousState?.details[2]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
              }
            }
            /*,()=>{console.log('finisddhed '+key[1]+'/n'+i );++i;this.forceUpdate();}*/
            );
            
            }
            if(key[0]=='city'){
            this?.setState((previousState)=> 
            {
              return {
                objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]] ,[...previousState?.details[1]??[]] ,[...previousState?.details[2]??[],key[1]] ,[...previousState?.details[3]??[]] ,[...previousState?.details[4]??[]] ,[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
              };
            }
            /*,()=>{console.log('finished '+this.state.details+'/n'+i );++i;this.forceUpdate();}*/
            );
            
            }
            if(key[0]=='company'){
            this?.setState((previousState)=> 
            {
              return {
                objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[],key[1]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
              }
            }
            /*,()=>{console.log('finished '+key[1] );++i;this.forceUpdate();}*/
            );
            
            }
            if(key[0]=='description'){
            this?.setState((previousState)=> 
            {
              return {
                objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[],key[1]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
              }
            }
            ,()=>{this.forceUpdate();}
            );
            
            }
            if(key[0]=='salary'){
            this?.setState((previousState)=> 
            {
              return {
                objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[],key[1]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
              }
            }
            /*,()=>{console.log('finished the SALARY'+this.state.details+'/n'+i );++i;this.forceUpdate();}*/
            );
            
            }
              if(key[0]=='url'){
                this?.setState((previousState)=> 
                {
                  return {
                    objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[],key[1]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
                  }
                }
                ,()=>{this.forceUpdate();}
                );
                
                }
                if(key[0]=='country'){
                  this?.setState((previousState)=> 
                  {
                    return {
                      objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[],key[1]]],selected:previousState?.selected??0
                    }
                  }
                  ,()=>{this.forceUpdate();}
                  );
                  
                  }

          }
          
          );
         
  
         /* for(let i =0;i<this.elements.length;i++){
            console.log(this.elements[i])
            const obj = JSON.parse(this.elements[i])
            this.titles.push(obj.Title)
          }*/
        });
      });
  //continue to add objects normally
    });
    //end of then
    
    
    }
  constructor(props){
    super(props);
    console.log('this.props.count ='+this.props.count)
    this.setState(
      ()=> { return {objects:[],details:[],selected:0}}
      );
    //bind this object to this object of this class instead of the browser window
    this.displayMore = this.displayMore.bind(this);
    const product = new URLSearchParams(window.location.search).get('q');

    setInterval(()=>{
      if(window.innerWidth<992&&$('.navbar-toggler').hasClass('collapsed')){
        $('#upperContainer').css('padding-bottom','0px');
      }
      else{
        $('#upperContainer').css('padding-bottom','30px');
      }
    })
    /*
    setInterval(()=>{
      //setChild Container to top
      if((typeof this?.state?.selected!== 'undefined' && typeof this?.state?.selected!== 'null')&& (typeof this?.state?.details[0]!== 'undefined')&& (typeof this?.state?.details[0]!== 'null'))
      if(typeof document.getElementById('Child')?.getBoundingClientRect()?.bottom-window!== 'null' && typeof document.getElementById('Child')?.getBoundingClientRect()?.bottom-window !== 'undefined')
      if(typeof window?.innerHeight!== 'null' && typeof window?.innerHeight!== 'undefined')
      if(document.getElementById('Child').getBoundingClientRect().bottom+20<window.innerHeight){
      document.getElementById("Child").style.marginTop=parseInt(getComputedStyle(document.getElementById('Child')).marginTop)+5+"px";
      
    }
    else if(document.getElementById('Child').getBoundingClientRect().bottom>window.innerHeight){
      if(getComputedStyle(document.getElementById('Child')).marginTop>0){
      document.getElementById("Child").style.marginTop=parseInt(getComputedStyle(document.getElementById('Child')).marginTop)-10+"px";
    }
      else{
        if(document.getElementById('Child').getBoundingClientRect().top>1&& parseInt(getComputedStyle(document.getElementById('Child')).marginTop)>0){
        document.getElementById("Child").style.marginTop=parseInt(getComputedStyle(document.getElementById('Child')).marginTop)-7+"px";
      }
        /* document.getElementById("Child").style.marginTop=parseInt(getComputedStyle(document.getElementById('Child')).marginTop)-10+"px"; */
     /* document.getElementById("Child").style.marginTop=parseInt(0)+"px"; 
    }
    }
    },1)*/
  
    //setobjects
  axios.post('https://api.jobmark.org/JobFind/SearchForJob?term='+new URLSearchParams(window.location.search).get('q')+'&p=1').then(response=>{
this.setState(
  (previousState) => { 

  if(previousState!=null)
  return {objects :[...previousState.objects],details:[...previousState.details] ,selected:previousState?.selected??0}
  else
  return {objects :[response.data],details:[],selected:previousState?.selected??0}
  }
  );
  })
   //setTitles
   setTimeout(()=>{
    var i = 0;
    if(this.state?.objects){ 
    Object.entries(this.state.objects).map((element)=>
    {
        Object.entries(element[1]).map((internalElement)=> {//each Job comes here
        Object.entries(internalElement[1]).map((key,val)=>{//each attribute (For Job) comes here
          //6 criteria to Consider
          if(key[0]=='Title'){
          this.setState((previousState)=> {return {objects:[...previousState?.objects], details:[[...previousState?.details[0]??[],key[1]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0 }});
          
          }
          if(key[0]=='Date'){
          this.setState((previousState)=> 
          {
            return {
              objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[],key[1]],[...previousState?.details[2]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
            }
          }
          /*,()=>{console.log('finisddhed '+key[1]+'/n'+i );++i;this.forceUpdate();}*/
          );
          
          }
          if(key[0]=='city'){
          this.setState((previousState)=> 
          {
            return {
              objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]] ,[...previousState?.details[1]??[]] ,[...previousState?.details[2]??[],key[1]] ,[...previousState?.details[3]??[]] ,[...previousState?.details[4]??[]] ,[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
            };
          }
          /*,()=>{console.log('finished '+this.state.details+'/n'+i );++i;this.forceUpdate();}*/
          );
          
          }
          if(key[0]=='company'){
          this.setState((previousState)=> 
          {
            return {
              objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[],key[1]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
            }
          }
          /*,()=>{console.log('finished '+key[1] );++i;this.forceUpdate();}*/
          );
          
          }
          if(key[0]=='description'){
          this.setState((previousState)=> 
          {
            return {
              objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[],key[1]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
            }
          }
          /*,()=>{console.log('finished '+key[1]+'/n'+i );++i;this.forceUpdate();}*/
          );
          
          }
          if(key[0]=='salary'){
          this.setState((previousState)=> 
          {
            return {
              objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[],key[1]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
            }
          }
          /*,()=>{console.log('finished the SALARY'+this.state.details+'/n'+i );++i;this.forceUpdate();}*/
          );
          
          }
            if(key[0]=='url'){
              this.setState((previousState)=> 
              {
                return {
                  objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[],key[1]],[...previousState?.details[7]??[]]],selected:previousState?.selected??0
                }
              }
              ,()=>{this.forceUpdate()}
              );
              
              }
              if(key[0]=='country'){
                this?.setState((previousState)=> 
                {
                  return {
                    objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[],key[1]]],selected:previousState?.selected??0
                  }
                }
                ,()=>{this.forceUpdate();}
                );
                
                }
        }
        
        );
       

       /* for(let i =0;i<this.elements.length;i++){
          console.log(this.elements[i])
          const obj = JSON.parse(this.elements[i])
          this.titles.push(obj.Title)
        }*/
      });
    });
  }
}
    ,3000);
      

  
     


    function stripHtml(html){
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}
$('#ParentContainer').scroll(function() { 
  $('#Child').animate({top:$(this).scrollTop()});
});
}


  render() {
    return(
    <div>
        <Navbar expand="lg">
        <div className='container-fluid Navbr-Lets'>
          <Navbar.Brand >
           <a href="/"><img src={Logo} id="logo" class="logo"  style={{  height:"38px" }} /></a>
          </Navbar.Brand>
          <div style={{
          backgroundColor: '#FF5400',
          marginBottom: '10px',
          paddingBottom: '30px',
          width:'100%',
          
        }}
        id="upperContainer"
        >
          <Navbar.Toggle aria-controls="navbarScroll" />
          <div>
          <Navbar.Collapse className='collapse-Nav' id="navbarScroll">
            <form className="element"  action="/SearchForJob" method="get" onSubmit={()=>{window.open('/SearchForJob?q='+document.getElementById('SearchBox').value ,"_self")}}>
              <div class="d-flex" id="searchBar" style={{marginLeft:'39px'}} >
                
            <Form.Control
              type="search"
              placeholder="Marketing Manager"
              className="me-2 Occupation"
              aria-label="Search"
              style={{
                height:'60px',
              }}
              id="SearchBox"
              name="q"
            />

           {/*<Form.Control
              type="search"
              placeholder="Location"
              className="me-2 Occupation secondary"
              aria-label="Search"
              style={{
                height:'60px',
              }}
              
            />*/} 

            <Button
                style={{
                  height:'60px',
                  width: '184px',
                  backgroundColor: '#e96320',
                  color: '#fff',
                  outlineColor: '#e96320',
                  borderColor: '#fff',
                }}
                onClick={() =>window.open('/SearchForJob?q='+document.getElementById('SearchBox').value ,"_self")}
                id="SearchButton"
                className="secondary"

            >
              Search Now
            </Button>
            
            </div>
          </form>
            {/*<img src={navicon} alt="" title='' style={{}}/*/}
          </Navbar.Collapse>
          </div>
        </div>
        </div>
      </Navbar>
       <div>
        <ul className='bread'  style={{ listStyle: 'none', display: 'flex' }}>
          <li className='b-home'>Home /</li>
          <li className='b-find'>Findjobs /</li>
          <li><span className='b-home'> Job Listings for </span><span className='b-find' > {new URLSearchParams(window.location.search).get('q')} </span></li>
        </ul>
      </div>{/*
      <div className='Result' >
       <p>Job Search Results <img src={icons} title="" /> </p>
       <p><span className='numCount' >1 to 50 of 1,071 for </span> <span> "graphic designer"; </span> <span className='numCount' >USA</span></p>
              </div> */}

      <div class="d-flex justify-content-between container-sm R0w-e" id="ParentContainer">
        <div>
        {
             
           this?.state?.details[0]?.map((val,index)=>{
            function html_entity_decode(message) {
              return message.replace(/[<>'"]/g, function(m) {
                return '&' + {
                  '\'': 'apos',
                  '"': 'quot',
                  '&': 'amp',
                  '<': 'lt',
                  '>': 'gt',
                }[m] + ';';
              });
            }
            function decodeHtml(html) {
              var txt = document.createElement("textarea");
              txt.innerHTML = html;
              return txt.value;
          }
            function Difference_In_Time(Date1 ,Date2 =new Date())
            {
              return Date2-Date1;
            }
            function toDays (ms) {
              const days = Math.floor(ms / (24*60*60*1000));
              return days;
            }
            function stripHtml(html)
            {
             let tmp = document.createElement("DIV");
              tmp.innerHTML = html;
              return tmp.textContent || tmp.innerText || "";
            }
            function HTML(input){
             return stripHtml(decodeHtml(html_entity_decode(input)));
            }
            return (
           <div className='Designer' key={index} onClick={()=>{this.setState((previousState)=> { return {objects:[...previousState?.objects], details:[[...previousState?.details[0]??[]],[...previousState?.details[1]??[]],[...previousState?.details[2]??[]],[...previousState?.details[3]??[]],[...previousState?.details[4]??[]],[...previousState?.details[5]??[]],[...previousState?.details[6]??[]],[...previousState?.details[7]??[]]],selected:index }},()=>{this.forceUpdate();})}}>
            <div className="Graphic" >
             <p className='Design-G'>{html_entity_decode(this.state.details[0][index]/*setTitle*/)}</p>
            
             <p>{toDays(Difference_In_Time(Date.parse(this.state.details[1][index])))} days Ago</p>
             </div>
                {/*<button className='PercentJob' >100% Remote Job</button>
                 <button className='fullJob' >Full-Time</button>*/}
                 <p className='Minimum' dangerouslySetInnerHTML={{__html:HTML(this.state.details[4][index])}}></p>
                    </div>
           );
          }
           )}
              {
                Object.entries({First:[]}).map(()=>{
                  return(
                    (typeof this?.state?.selected!== 'undefined' && typeof this?.state?.selected!== 'null')&& (typeof this?.state?.details[0]!== 'undefined')&& (typeof this?.state?.details[0]!== 'null') && <div className='See' style={{marginBottom:'25px'}}>
    
                  <button className='SeeMore' onClick={async ()=>{ await this.increment(); await this.displayMore()}}>See More</button>
                </div>
                  );
                }
                )
                }
        </div>
        <div id="parent">
           {
            
            Object.entries({First:[]})?.map((val,index)=>{
              function html_entity_decode(message) {
                return message.replace(/[<>'"]/g, function(m) {
                  return '&' + {
                    '\'': 'apos',
                    '"': 'quot',
                    '&': 'amp',
                    '<': 'lt',
                    '>': 'gt',
                  }[m] + ';';
                });
              }
              function decodeHtml(html) {
                var txt = document.createElement("textarea");
                txt.innerHTML = html;
                return txt.value;
            }
              function Difference_In_Time(Date1 ,Date2 =new Date())
              {
                return Date2-Date1;
              }
              function toDays (ms) {
                const days = Math.floor(ms / (24*60*60*1000));
                return days;
              }
              function stripHtml(html)
              {
               let tmp = document.createElement("DIV");
                tmp.innerHTML = html;
                return tmp.textContent || tmp.innerText || "";
              }
              function HTML(input){
               return stripHtml(decodeHtml(html_entity_decode(input)));
              }
              return(
                (typeof this?.state?.selected!== 'undefined' && typeof this?.state?.selected!== 'null')&& (typeof this?.state?.details[0]!== 'undefined')&& (typeof this?.state?.details[0]!== 'null') &&<div class='RightDesigner' id="Child" >
              <div className='detailDesign' >
              {
              (typeof this?.state?.selected!== 'undefined' && typeof this?.state?.selected!== 'null')&& (typeof this?.state?.details[0]!== 'undefined')&& (typeof this?.state?.details[0]!== 'null') &&
              <p>{this.state.details[0][this.state.selected]}</p>
              }
              {
               (typeof this?.state?.selected!== 'undefined' && typeof this?.state?.selected!== 'null')&& (typeof this?.state?.details[7]!== 'undefined')&& (typeof this?.state?.details[7]!== 'null') &&
              <button>{((this?.state?.details[7][this?.state?.selected])=='us')?"USA":((this?.state?.details[7][this?.state?.selected])=='gb')?"UK":this?.state?.details[7][this?.state?.selected]}</button>
              }
              </div>
              <div className='Tl-Grape-pera'>
                <button className='JobDl' >Job Description</button>
              {
              (typeof this?.state?.selected!== 'undefined' && typeof this?.state?.selected!== 'null')&& (typeof this?.state?.details[0]!== 'undefined')&& (typeof this?.state?.details[0]!== 'null') &&
              <div className='lastFlex'>
                 <div dangerouslySetInnerHTML={{__html: decodeHtml(this.state.details[4][this.state.selected] )}} ></div>
                 <div></div>
              <a href={this.state.details[6][this.state.selected] } target="_blank"><button className='ApplyNow' style={{marginTop: "10px"}}>Apply Now</button></a>
                </div>
                }
               </div>
         </div>
              );
            }
          )
       }
        </div>
      </div>
    </div> 
    
    );
  }
  
}
const mapDispatchToProps = state => {
  return {
    count: state.count
  };
};
export default connect(mapDispatchToProps)(JobSearchPage)