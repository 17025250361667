import  {useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
export default function EmailConfirmation({toggleIsActive}){
    const handleClose = () => setOpen(true);
    const [open, setOpen] = useState(false);
    const [showElement, setShowElement] = useState(true);
    const navigate = useNavigate();
    const style = {
        position:  'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '0px solid #000',
        boxShadow: 24,
        p: 4,
      };
    
    useEffect(()=>{
        const email = new URLSearchParams(window.location.search).get('email')
        const code = new URLSearchParams(window.location.search).get('code')
        const confirmData = {
            email:email,
            code:code
        };
        toggleIsActive();

        axios.post(process.env.REACT_APP_BACKEND_URL+'/api/app/users/confirm-user',confirmData).then(
        (res)=>{
            if(res.data.succeeded==true){
                setOpen(true);
                setShowElement(false);
                toggleIsActive();
            }
        }
        );
    },[]);
    return (
    <div>{
       showElement && 
    <h1 class="">
        Please wait ...
    </h1>
    }
    <Modal
        open={open}
        onClose={handleClose}
      >
        <Box sx={style}>
          <h1>Your Email has been Confirmed! </h1>
          <div className='d-flex align-items-center'>
            <h2 className='d-inline-block'>You can now login to jobmark here:&nbsp;</h2>
            <button className='btn btn-primary button' onClick={()=>{navigate('/login');}} >login</button>
            </div>
        </Box>
      </Modal>
    </div>
    );
}