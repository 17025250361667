import Sidebar from "./Sidebar.js";
import React from "react";
import Content from './Content.js';
import { useEffect } from "react";
export default function Dashboard({isActive,setIsActive}) 
{

  function handleClick ()  {
    setIsActive(prevIsActive => !prevIsActive)
  };
  function togglesidebar(){
    //move sidebar to the left
    document.getElementsByClassName('main-sidebar')[0].classList.toggle('sidebarHidden');
    //toggle main view margin
    document.getElementById('mainView').classList.toggle('mainViewMarginLeft');
    document.getElementById('overlay').classList.toggle('hidden');

  }
 
  return(
    <div id="dashboard">
    <div id="overlay" onClick={togglesidebar}></div>
    <Sidebar isloggingout={isActive} sendIsActiveToApp={handleClick} />
    <Content />
     
    </div>
  );
}

