

import { IonIcon } from '@ionic/react';
import { menuSharp,closeOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import $ from 'jquery';

export default function ApplicantView(){
    const {id,applicantid} = useParams();
    const [ApplicantURL,setApplicantURL] =useState('');
    const navigate = useNavigate();
    useEffect(()=>{
        axios.get(process.env.REACT_APP_BACKEND_URL+`/api/app/job/${applicantid}/applicant`,{withCredentials: true})
        .then((res)=>{
            setApplicantURL(res.data.url);
        })
        
        ;
    },[]);
    return (
        <div className="PdfContainer bg-white">
        <IonIcon icon={closeOutline} id="ClosePDF" onClick={()=>{navigate(`/dashboard/myjobs/job/${id}`)}}></IonIcon>
        <object
      data={ApplicantURL}
      height="100%"
      width="100%"
      type="text/html"
      id="viewer"
    >
      Alternative Content
    </object>
        </div>
    );
}