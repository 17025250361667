import MobileLogin from '../mobile_login.svg';
import {useState} from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import { useEffect } from 'react';
export default function ResetPasswordConfirm({toggleIsActive}){
    const [showSpinner, setShowSpinner] = useState(false);
    const [showBackground, setShowBackground] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [PasswordResetSuccess ,setPasswordResetSuccess] = useState(false);
    const navigate = useNavigate();
    const handleResetButtonClick = (e) =>{
        e.preventDefault();
    /*setShowSpinner(prevShowSpinner => !prevShowSpinner);
    setShowBackground(prevShowSpinner => !prevShowSpinner);*/
    const string = new URLSearchParams(window.location.search).get('code')
    if(password==confirmpassword){
        toggleIsActive();
    axios.post(
        process.env.REACT_APP_BACKEND_URL+"/api/app/users/confirm-reset-password",
        {email:new URLSearchParams(window.location.search).get('email'),code:encodeURIComponent(new URLSearchParams(window.location.search).get('code')).replace(/%20/g,'+').replace(/%2F/g,'/'),password:password}
    ).then((response)=>{
        console.log(response)
        if(response.data.succeeded){
        toggleIsActive();
        setPasswordResetSuccess(true);
        navigate('/login');
    }
    }
    ).catch((error) => {
        console.log(error);
      });
    }

    }

return (
    <div class="top-container">
        <div class="img-container">
        <img src={MobileLogin} class="img-fluid"/>
        </div>
        <form onSubmit={handleResetButtonClick}>
    <div id="forget-password-container">
        <div class="new-password-container">
    <div className={`${showSpinner ? 'spinner-local' : ''}`}></div>
      <div className={`${showBackground ? 'background-grey' : ''}`}></div>
  
    <div class="d-flex flex-column align-items-center">
        <h1>
    Enter your new password
    </h1>
    {/*<label for="exampleInputEmail1">Email:</label>*/}
    <input type="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="New Password"  value={password} onChange={(e) => setPassword(e.target.value)}/>
    <input type="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Repeat the new password"  value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)}/>
    <button type="submit" class="form-control btn btn-primary col-3" id="ForgetPasswordButton"  aria-describedby="emailHelp" placeholder="Email"/>
            </div>
            </div>
            <div class={`password-reset-msg ${PasswordResetSuccess ? 'show':  ''}`}>
            Password Reset Successfully!
        </div>
            </div>
            </form>
    </div>
);
}