import React, { useState ,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Navbar, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import "./JobPostingPage.css";
import $ from 'jquery';
import axios from 'axios';
import Footer from './Footer'
import ApplyContainer from './ApplyContainer';
function JobPostingPage () {
  const [title,setTitle] = useState('');
  const [country,setCountry] = useState('');
  const [city,setCity] = useState('');
  const [company,setCompany] = useState('');
  const [description,setDescription] = useState('');
  const [url,setUrl] = useState('');
  const [salary,setSalary] = useState('');
  const [date,setDate] = useState('');
  const [validThrough,setValidThrough] = useState('');
  const [addressRegion,setaddressRegion] = useState('');
  const [addressCountry,setaddressCountry] = useState('');
  const [addressLocality,setaddressLocality] = useState('');
  const [countryCode,setcountryCode] = useState('');
  const [isFinished,setisFinished] = useState(false);
  function stripHtml(html){
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
 }
 function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}
function html_entity_decode(message) {
  return message.replace(/[<>'"]/g, function(m) {
    return '&' + {
      '\'': 'apos',
      '"': 'quot',
      '&': 'amp',
      '<': 'lt',
      '>': 'gt',
    }[m] + ';';
  });
}

  useEffect(()=>{
      axios.get(process.env.REACT_APP_BACKEND_URL+'/api/app/job/'+new URLSearchParams(window.location.search).get('PositionId')+'/job-post').then(function(response){
        setTitle(response.data.title);
        setCompany(response.data.companyName);
        setDate(response.data.creationTime);
        setCountry(response.data.city.country_name);
        setCity(response.data.city.name);
        setValidThrough(response.data.applicationDeadLine);
        setDescription(response.data.description);
        setSalary(response.data.salary);
        setaddressRegion(response.data.city.state_code);
        setaddressCountry(response.data.city.country_code);
        setaddressLocality(response.data.city.name);
        setcountryCode(response.data.city.country_code);
      });
  },[]);
/*
  useEffect(
      ()=>{
      setInterval(()=>{
        if(title!=''){
       document.getElementById('divPadding').style.paddingLeft=(window.innerWidth<770)?"0px":"9px";
       document.getElementById('headerFindWork').style.paddingLeft =document.getElementById('logo').getBoundingClientRect().left+"px";
        
       if(window.innerWidth<992&&$('.navbar-toggler').hasClass('collapsed')){
          $('#upperContainer').css('padding-bottom','0px');
        }
        else{
          $('#upperContainer').css('padding-bottom','30px');
        }
        }
      },1);}
      );
*/
      
  return (
<>

{(title!='')
&&
 <script type="application/ld+json">{JSON.stringify({
    "@context" : "https://schema.org/",
    "@type" : "JobPosting",
    "title" : title,
    "description" : description,
    "datePosted" : date,
    "validThrough" : validThrough,
    "employmentType" : "CONTRACTOR",
    "hiringOrganization" : {
      "@type" : "Organization",
      "name" : company,
      "sameAs" : "",
      "logo" : ""
    },
    "jobLocation": {
    "@type": "Place",
      "address": {
      "@type": "PostalAddress",
      "addressLocality": addressLocality,
      "addressRegion": addressRegion,
      "postalCode": "",
      "addressCountry": addressCountry
      }
    },
    "baseSalary": {
      "@type": "MonetaryAmount",
      "currency": "USD",
      "value": {
        "@type": "QuantitativeValue",
        "value": "",
        "unitText": ""
      }
    }
  })}</script>}
      <Navbar expand="lg">
        <div className='container-fluid Navbr-Lets ' >
          <Navbar.Brand  style={{backgroundColor:'#fff'}}>
            <a href="/"><img src="../images/mainLogo.png" id="logo" class="logo" style={{  height:"38px" }} /></a>
          </Navbar.Brand>
          <div style={{
          backgroundColor: '#FF5400',
          marginBottom: '10px',
          paddingBottom: '30px',
          width:'100%',
          
        }}
        id="upperContainer">
          <Navbar.Toggle aria-controls="navbarScroll" />
          <div>
          <Navbar.Collapse className='collapse-Nav' id="navbarScroll">
          <form className="element"  action="/SearchForJob" method="get" onSubmit={()=>{window.open('/SearchForJob?q='+document.getElementById('SearchBox').value ,"_self")}}>
               <div class="d-flex" id="searchBar" style={{marginLeft:'39px'}}>
              <Form.Control
              type="search"
              placeholder="Marketing Manager"
              className="me-2 Occupation"
              aria-label="Search"
              style={{
                height:'60px',
              }}
              id="SearchBox"
              name="q"
            />
            {/*<Form.Control
              type="search"
              placeholder="Location"
              className="me-2 Occupation secondary"
              aria-label="Search"
              style={{
                height:'60px',
              }}
              
            />*/}
           
           <Button
                style={{
                  height:'60px',
                  width: '184px',
                  backgroundColor: '#e96320',
                  color: '#fff',
                  outlineColor: '#e96320',
                  borderColor: '#fff',
                }}
                onClick={() =>window.open('/SearchForJob?q='+document.getElementById('SearchBox').value ,"_self")}
                id="SearchButton"
                className="secondary"

            >
              Search Now
            </Button>
            </div>
          </form>
            {/*<img src={navicon} alt="" title='' style={{}}/*/}
          </Navbar.Collapse>
          </div>
        </div>
        </div>
      </Navbar>
      <p id="headerFindWork">Find Remote, Hybrid and Flexible Jobs for your Career</p>
      <hr></hr>
      {
      Object.entries({First:""}).map(()=> {
        return (
          
      (title!='') ?
      <>
      <div id="divPadding" style={{ paddingLeft:'9px',maxWidth:'1369px' }}>
      <Container fluid>
        <Row>
          <Col
            md={10}>
            <div style={{
              backgroundColor: '#FF5400',
              color: '#fff',
              height: 'calc(421px + 1.5vw)',
              borderRadius: '19px',
              paddingRight:'32px',
            }}>
            <h1 style={{paddingBottom: '50px',paddingTop:'67px', paddingLeft: '50px' }}>{title}</h1>
            <p style={{ paddingBottom: '30px', paddingLeft: '50px'  }} class="JobGeneralD">
              {stripHtml(company)},{city} ,{countryCode}
            </p>
            {/*<p style={{ paddingBottom: '40px',color:'#fff' , paddingLeft: '50px'}} class="JobGeneralD">
              11-50 employees - stuffing and Recruiting
          </p>*/}
             <a href={url}><button style={{float: 'right' ,borderRadius:'28px' ,width:'182px' ,borderWidth:'0px'}} ><p style={{color:"#FF5400" , marginTop:'9px' ,marginBottom:'9px'}} onClick={(e)=>{e.preventDefault();document.querySelector('.Apply').scrollIntoView({ behavior: 'smooth' });}}>Apply Now</p></button></a>
            </div>
          </Col>
          <Col md={1}></Col>{/*for spacing only*/}
        </Row>
      </Container>
      {/*
      <Container   fluid>
        
        <Row>
          <Col md={10}>
            <div style={{
              backgroundColor: '#F6F6F6',
              padding:'50px',
              borderRadius: '10px',
            }}>
            <h1>Meet the Company Owner For meetup</h1>
            <div className='wolfman' >
                <div >
              <img src={owner} alt="" srcset="" />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '50px',
                }}
              >
                <h2>Allan Wolfman</h2>
                <p>CEO</p>
              </div>
              <div
              className='MessageParent'
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
              >
                <button
                  style={{
                    padding: '10px',
                    borderRadius: '60px',
                    width: '250px',
                    height: '50px',
                  }}
                >
                  <h5>Message Now</h5>
                </button>
              </div>
            </div>
            </div>
          </Col>
          <Col md={1}></Col>
        </Row>
      </Container>
                */}
      <Container fluid>
        <Row>
          <Col md={10}>
          <div   
            class="About"
            >
              <div style={{marginTop: '20px'}} id="TextContainer">
            <h2 style={{ marginBottom: '30px', fontWeight:600}} > About the position</h2>
            <div dangerouslySetInnerHTML={{__html:decodeHtml(description)??''}}></div>
            </div>
            </div>
          </Col>
          <Col md={10}>
          <div   
            class="About Apply"
            >
              <div style={{marginTop: '20px'}} id="TextContainer">
            <div><ApplyContainer setisFinished={setisFinished} isFinished={isFinished} /></div>
            </div>
            </div>
          </Col>
          <Col md={1}></Col>
        </Row>
      </Container>
      {/*
      <Container fluid>
        <Row>
          <Col
            md={10}
          >
            <div className='AboutText'  style={{
              marginTop: '80px',
              backgroundColor: '#F6F6F6',
              padding: '30px',
              borderRadius: '10px',
            }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '50px',

                }}
              >
                <h2>Set alert for similar job</h2>
                <p style={{ fontSize: '20px' }}>
                  Job, CareerUp, Inc. Sydney, New South Wales, Australia
                </p>
              </div>
              <div
              className='Sydney'
              >
                <img
                  src="../images/ring.png"
                  alt=""
                  srcset=""
                  style={{
                    padding: '10px',
                    borderRadius: '60px',
                    width: '100px',
                    height: '100px',
                  }}
                />
              </div>
            </div>
          </Col>
          <Col md={1}></Col>
        </Row>
                </Container>*/}
                {/*
      <Container fluid>
        <Row>
          <Col md={10}>
          <div  
            style={{
              backgroundColor: '#F6F6F6',
              borderRadius: '10px',
              padding: '40px',
              marginTop:'80px'
            }}>
            <h3 style={{padding:'30px'}}>About The Company</h3>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{fontSize:'20px'}}>
                Staffing and recruiting 11-50 employees 99 on linkedinHundreds
                of coperate partners
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{fontSize:'20px'}}>
                Thousand of alumni. Professional Career Coaches. Specialized
                internship recruiters.
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <p style={{fontSize:'20px'}}>In demand skils training. Let's launch your career today</p>
              <button
                  style={{
                    padding: '10px',
                    marginLeft:'20px',
                    borderRadius: '60px',
                    width: '150px',
                    height: '50px',
                    borderColor:'#6E6E6E',
                    color:'#6E6E6E',
                    float:"right"
                  }}
                >
                  <p >follow us</p>
                </button>
            </div>
          </div>
          </Col>
          <Col md={1}></Col>
        </Row>
      </Container>
                */}
      {/*<Container>
        <Row>
            <Col><h4 className='Similar' >See More Similar Jobs</h4></Col>
            <Col>
            <button style={{
                width: '150px',
                backgroundColor: '#e96320',
                color: 'white',
                border:'none',
                borderRadius:'60px',
                padding:'10px',
                borderColor:'#e96320',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                marginTop:'100px',
              }}
              onClick= {() => {}}
              >See More</button>
            </Col>
            
        </Row>
      </Container>
    */}
      </div>
      <Footer />
</>:
<div className="apply-loading">
<div className="internal-loading">
            <div className="loader-parent">
              <div className="loader"></div>
              </div>
          </div>
          </div>
      )
    }  
    )
  }
  
      </>
    
  );
};

export default JobPostingPage;