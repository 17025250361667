import { AiOutlineArrowDown } from 'react-icons/ai'
const Item = [
    {
       id:1,
       dateUpdate : "7 Aug", 
       image : "../images/laptop.png",
       btn : "Full-Name",
       btn2 : "Finance",
       pera : "Finance, Manager",
       pera1 : "Manhattan, NY",
       pera2 : "Responsible to manage the finance  in one of our department , making reports for our  income and expenses"
    },
    {
      id:2,
      dateUpdate : "14 Aug", 
      image : "../images/laptop.png",
      btn : "Full-Name",
      btn2 : "Design",
      pera : "Finance, Manager",
      pera1 : "Manhattan, NY",
      pera2 : "Responsible to manage the finance  in one of our department , making reports for our  income and expenses"
   },
   {
      id:3,
      dateUpdate : "24 Aug", 
      image : "../images/laptop.png",
      btn : "Full-Name",
      btn2 : "Design",
      pera : "Finance, Manager",
      pera1 : "Manhattan, NY",
      pera2 : "Responsible to manage the finance  in one of our department , making reports for our  income and expenses"
   },
   {
      id:3,
      dateUpdate : "28 Aug", 
      image : "../images/laptop.png",
      btn : "Full-Name",
      btn2 : "Finance",
      pera : "Finance, Manager",
      pera1 : "Manhattan, NY",
      pera2 : "Responsible to manage the finance in one of our department , making reports for our  income and expenses"
   },
   {
      id:3,
      dateUpdate : "5 Set", 
      image : "../images/laptop.png",
      btn : "Full-Name",
      btn2 : "Finance",
      pera : "Finance, Manager",
      pera1 : "Manhattan, NY",
      pera2 : "Responsible to manage the finance  in one of our department , making reports for our  income and expenses"
   },
   {
      id:3,
      dateUpdate : "12 Set", 
      image : "../images/laptop.png",
      btn : "Full-Name",
      btn2 : "Finance",
      pera : "Finance, Manager",
      pera1 : "Manhattan, NY",
      pera2 : "Responsible to manage the finance in one of our department , making reports for our  income and expenses"
   },
    
     
]
export default Item;