import { useEffect, useState } from "react";
import SearchNavBar from "./SearchNavbar";
import Footer from "./Footer";
import {IonIcon} from '@ionic/react';
import {arrowBackSharp } from 'ionicons/icons';
import ApplyContainer from "./ApplyContainer";
import { useSearchParams } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios';
export default function NewJobSearchPage(){
    const [jobs,setJobs] = useState(null);
    const [JobPost,setJobPost] = useState(null);
    const [isFinished,setisFinished] = useState(false);
    const [isLoading,setisLoading] =useState(false);
    const [searchParams] = useSearchParams();
    useEffect(()=>{
        const title = searchParams.get("title");
        const location = searchParams.get("location");
        const queryParams = new URLSearchParams();
        if (title) {
            queryParams.append('title', encodeURIComponent(title));
          }
          
          if (location) {
            queryParams.append('location', encodeURIComponent(location));
          }
          
          queryParams.append('page', '1');
          
          axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/app/job/get-search-results?${queryParams}`).then((res) => { if(res.status==200){
                setJobs(res.data);
                setJobPost(res.data[0]);
                }
            }
        );
    },[]);
function postcardactive(e){
    $('.post-card').removeClass('active');
    e.currentTarget.classList.add('active');
}
    function toggleJobPostList(){
        $('#jobList').toggleClass('hideJobList');
        $('#JobPostTop1Container').toggleClass('hideJobPost');
    }
    function changeJobPostDetails(job){
        if(job!=JobPost){
        setJobPost(job);
        setisFinished(false);
        }
    }
     function strip_html_tags(str) {
        if (str === null || str === '') return '';
        else str = str.toString();
        
        // Replace long spaces, including &nbsp;, with a single space
        str = str.replace(/(<span>|&nbsp;|\s)+/g, ' ');
      
        return str.replace(/<[^>]*>/g, '');
      }
return(
   
    <div>
    <SearchNavBar />
    <div className="JobSearchContainer2 active" id="JobSearchContainer">
   {jobs||isLoading?<div className="d-flex justify-content-center col-11 col-lg-10 col-xl-9 gap-2 gap-md-5 mx-auto mb-2 " >
         <div className="col-11 col-md-6 d-flex flex-column gap-4 overflow-y-scroll" style={{height:'100vh'}} id="jobList" > 
       {jobs.map((job,index)=>
       (
            <div className={`rounded-3 post-card p-4 border border-2 border-black rounded-3  ${index === 0 ? 'active' : ''}`} tabIndex="0" onClick={(e)=>{changeJobPostDetails(job);toggleJobPostList();postcardactive(e);}}>
            <div className="mb-1">
            {job.title}
            </div>
            <span>
                {strip_html_tags(job.description).slice(0,300).trim()}...
            </span>
            </div>
            )
        )}
        
        </div>
        {jobs?
        <div className="col-11 col-md-6 d-flex border border-2 border-black rounded-3 hideJobPost" id="JobPostTop1Container">
        <div className="jobpostTopContainer align-self-end flex-grow-1 position-sticky" style={{height:'1000px'}} >
        <span className="jobpost w-100 p-2 d-flex flex-column"  id="jobpost">
            <div onClick={()=>{toggleJobPostList()}}><IonIcon icon={arrowBackSharp} id="arrowbacksharp"></IonIcon></div>
            <div>
            <h2 className="d-inline pe-4" id="title">{JobPost?(JobPost.title):<span></span>}</h2>
            <span className="pe-4">
            <h4 className="d-inline border border-black rounded-1 p-1" id="Country">{JobPost?(JobPost.city.country_name):<span></span>}</h4>
            </span>
            <span className="pe-2">
            <h4 className="d-inline border border-black rounded-1 p-1" id="City">{JobPost?(JobPost.city.name):<span></span>}</h4>
            </span>
            </div>
            <hr></hr>
            <h2>Description</h2>
            <div className="mb-5" id="Description">{JobPost?(<span dangerouslySetInnerHTML={{__html:JobPost.description}}></span>):<span></span>}</div>
            <div className="Apply">
            {JobPost?<ApplyContainer jobid={JobPost.id} setisFinished={setisFinished} isFinished={isFinished}/>:<ApplyContainer jobid={null} setisFinished={setisFinished} isFinished={isFinished}/>}
            </div>
        </span>
        </div>
        </div>:
        <div className="loading-bar">Test</div>}
    </div>:
    <div className="height-100vh">
    <div className="internal-loading"style={{zIndex: -1}}>
            <div className="loader-parent ">
              <div className="loader"></div>
              </div>
          </div>
            </div>
          }
    </div>
    <Footer />

    </div>
);
}